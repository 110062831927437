<template>
    <span>
        <el-cascader
            class="efdpc"
            v-model="localValue"
            @input="handleInput"
            :props="deptProps"
            @change="changeGroup"
            :options="meta.groups"
            ref="cas"
            :style="{ width: width ? width : 'auto' }"
            v-bind="$attrs"
            v-on="$listeners"
        />
    </span>
</template>
<script>
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'EfnGroupDeptCascader',
    props: {
        defaultValue: {
            type: Boolean,
            default: true,
        },
        selAllDept: {
            type: Boolean,
            default: true,
        },
        value: {
            type: Array,
            default: () => {
                return ['', ''];
            },
        },
        width: {
            type: String,
            default: '',
        },
    },
    data() {
        const _this = this;
        return {
            initedGroup: false,
            localValue: [],
            queryDeptByGroup: '/system/dept/deptTree',
            meta: {
                groups: [],
            },
            deptProps: {
                lazy: true,
                label: 'name',
                value: 'code',
                multiple: false,
                async lazyLoad(node, resolve) {
                    if (node.level === 0) {
                        resolve([]);
                    } else if (node.children.length !== 0) {
                        resolve([]);
                    } else if (!node.value) {
                        resolve([]);
                    } else {
                        resolve(await _this.refreshDept(node.value).catch(() => resolve([])));
                    }
                },
            },
        };
    },
    watch: {
        value: {
            handler: function () {
                this.initData();
            },
            deep: true,
        },
    },
    mounted() {
        this.initData('mount');
    },
    computed: {
        groupName() {
            return this.meta.groups.find((a) => a.code === this.localValue[0])['name'] || '';
        },
        deptName() {
            const depts = this.meta.groups.find((a) => a.code === this.localValue[0]).children || [];
            const _d = depts.find((a) => a.code === this.localValue[1]);
            return _d ? _d['name'] : '';
        },
        initGroupCode() {
            return this.value[0] ? this.value[0] : this.meta.groups.length ? this.meta.groups[0].code : '';
        },
    },
    methods: {
        async initData() {
            const _this = this;
            if (!this.initedGroup) {
                await this.initGroups();
            }
            if (this.defaultValue && (this.value || !this.value.length)) {
                const _group = this.initGroupCode;
                const _detps = (await this.refreshDept(_group)) || [];
                const _deptCode = this.value[1] ? this.value[1] : _detps.length ? _detps[0].code : '';
                const _index = this.meta.groups.map((a) => a.code).indexOf(_group);
                if (_deptCode) {
                    if (!_this.meta.groups[_index]['children'] || !_this.meta.groups[_index]['children'].length) {
                        _this.meta.groups[_index]['children'] = _detps;
                    }
                }
                this.localValue = [this.initGroupCode, _deptCode];
                this.handleInput(this.localValue);
                await this.changeGroup();
            }
        },
        async refreshDept(_deptCode) {
            const _depts = (await this.depts(_deptCode)) || [];
            if (this.selAllDept) {
                _depts.unshift({ code: '', name: '全部', leaf: true });
            }

            return _depts;
        },
        handleInput(data) {
            const _r = ['', ''];
            Object.assign(_r, data);
            this.$emit('input', _r);
        },
        async initGroups() {
            this.initedGroup = true;
            //没有默认组，组件不选择任何数据
            this.meta.groups = (await this.groups()) || [];
        },
        async groups() {
            return new Promise((resolve) => {
                UrlUtils.DeptGroup(this, (rst) => {
                    const deptGr = rst.map((a) => {
                        a.children = [];
                        a.leaf = false;
                        return a;
                    });
                    resolve(deptGr);
                });
            });
        },
        async depts(groupCode) {
            const _this = this;
            const rst = await this.$http.get(_this.queryDeptByGroup + '?deptGroupCode=' + groupCode);
            return rst['data'].data.map((item) => {
                item.leaf = true;
                return item;
            });
        },
        async changeGroup() {
            const _this = this;
            this.$emit('changeFull', {
                groupCode: _this.localValue[0],
                groupName: _this.groupName,
                deptCode: _this.localValue[1],
                deptName: _this.deptName,
            });
        },
    },
};
</script>
