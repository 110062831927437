<template>
    <el-autocomplete
        popper-class="el-autocomplete-down-self"
        :trigger-on-focus="false"
        placeholder="请输入名称或条码"
        :fetch-suggestions="onShowPopTable"
        v-bind="$attrs"
        v-on="$listeners"
        @focus="onFocus"
        ref="gps"
    >
        <template slot-scope="{ item }">
            <div style="width: 400px">
                <div style="display: inline-block; width: 200px; max-width: 200px">
                    {{ (item.value = item.sku.name) }}
                </div>
                <div style="text-align: center; display: inline-block; width: 100px; max-width: 100px">
                    {{ item.sku.specs }}
                </div>
                <div style="text-align: right; display: inline-block; width: 100px; max-width: 100px">
                    {{ item.retailPrice | money }}
                </div>
            </div>
            <hr class="efHr" style="background-color: #b1ceff" />
        </template>
    </el-autocomplete>
</template>

<script>
export default {
    name: 'EfnGoodPopSearch',
    props: {
        deptCode: {
            type: String,
            default: '',
        },
        skuType: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            tableSearch: '',
        };
    },
    watch: {
        deptCode() {
            this.value = '';
            this.$refs.gps.suggestions = [];
        },
    },
    methods: {
        onFocus() {
            const _gps = this.$refs.gps;
            _gps.suggestions = [];
            if (_gps.value) {
                _gps.getData(_gps.value);
            }
        },
        async onShowPopTable(queryString, cb) {
            if (!this.deptCode) {
                this.$message.info('请选择价格参考门店');
                return;
            }
            const _this = this;
            const rst = await this.$efApi.goodsApi.queryGoods({
                search: queryString,
                deptCode: _this.deptCode,
                goodsStatusIn: [],
                skuType: _this.skuType,
                page: 1,
                limit: 10,
            });
            cb(rst.data);
        },
    },
};
</script>
